import React from "react";
import "./style.css";
import LoginVideo from "../../images/invelistLogin.mp4";
import AddVideo from "../../images/invelistAdd.mp4";
import UpdatesVideo from "../../images/invelistUpdates.mp4";

export default function Roadmap() {
  return (
    <section className="section-how">
      <div className="container">
        <span className="subheading">Nasıl Başlayacağım?</span>
        <h2 className="heading-secondary">
          Aşağıdaki adımları takip ederek hemen başla
        </h2>
      </div>
      <div className="container grid grid--2-cols grid--center-v">
        <div className="step-img-box">
          <video
            src={LoginVideo}
            alt="Video"
            className="step-img"
            autoPlay
            loop
            muted
          />
        </div>
        <div className="step-text-box">
          <p className="step-number">01</p>
          <h3 className="heading-tertiary">Kayıt Ol</h3>
          <p className="step-description">
            Finansal yüklerinizi hafifletmek ve size destek olmak amacıyla özel
            olarak hazırlanan invelist uygulamasını App Store veya Google Play
            Store üzerinden ücretsiz olarak indirebilirsiniz. Uygulamaya kayıt
            olun ve gelirlerinizle giderlerinizi tek bir yerde kolayca yönetin.
          </p>
        </div>

        <div className="step-text-box">
          <p className="step-number">02</p>
          <h3 className="heading-tertiary">Yatırım Ekle</h3>
          <p className="step-description">
            Invelist'e giriş yaptıktan sonra, kripto varlıklarınızı, hisse
            senetlerinizi (şu an için Borsa İstanbul henüz desteklenmemektedir)
            ve daha fazlasını kolayca tek bir uygulamada takip edebilirsiniz.
          </p>
        </div>
        <div className="step-img-box">
          <video
            src={AddVideo}
            alt="Video"
            className="step-img"
            autoPlay
            loop
            muted
          />
        </div>

        <div className="step-img-box">
          <video
            src={UpdatesVideo}
            alt="Video"
            className="step-img"
            autoPlay
            loop
            muted
          />
        </div>
        <div className="step-text-box">
          <p className="step-number">03</p>
          <h3 className="heading-tertiary">Takipte Kal</h3>
          <p className="step-description">
            Uygulamamıza sürekli olarak yeni özellikler ekliyor ve geliştirmeler
            yapıyoruz. En son güncellemeleri ve haberleri kaçırmamak için bizi
            sosyal medya hesaplarımızdan takip edebilirsiniz.
          </p>
        </div>
      </div>
    </section>
  );
}
