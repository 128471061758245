import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../images/logo.png";
import "./style.css";

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <header className={`header ${isNavOpen ? "nav-open" : ""}`}>
      <Link to={"/"}>
        <img className="logo" src={Logo} alt="Invelist official logo" />
      </Link>
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li>
            <Link to={"/"} className="main-nav-link">
              Ana Sayfa
            </Link>
          </li>
          <li>
            <Link to={"/contact"} className="main-nav-link">
              İletişim
            </Link>
          </li>
          <li>
            <Link to={"/download"} className="main-nav-link">
              İndir
            </Link>
          </li>
        </ul>
      </nav>
      <button
        className={`btn-mobile-nav ${isNavOpen ? "nav-open" : ""}`}
        onClick={handleNavClick}
      >
        <FontAwesomeIcon
          className="icon-mobile-nav"
          name="menu-outline"
          icon={faBars}
        />
        <FontAwesomeIcon
          className="icon-mobile-nav"
          name="close-outline"
          icon={faXmark}
        />
      </button>
    </header>
  );
}
