import "./style.css";
import React from "react";
import Video from "../../images/invelist.mp4";

export default function Hero() {
  return (
    <section className="section-hero">
      <div className="hero">
        <div className="hero-img-box">
          <video
            src={Video}
            alt="Video"
            className="hero-img"
            autoPlay
            loop
            muted
          />
        </div>
        <div className="hero-text-box">
          <h1 className="heading-primary">Nedir Bu invelist?</h1>
          <p className="hero-description">
            Invelist, finansal yükünüzü azaltmak ve size destek olmak için özel
            olarak geliştirilmiş bir finans yönetim platformudur. Yatırımlarınız
            ile birlikte gelir ve giderlerinizi kolayca takip etmenizi sağlar.
          </p>
        </div>
      </div>
    </section>
  );
}
