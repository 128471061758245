import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Faq = () => {
  return (
    <div>
      <Navbar />
      <h1
        style={{
          backgroundColor: "#000",
          color: "#fff",
          margin: "25rem",
        }}
      >
        Aklınıza takılan soruları iletişim adreslerimiz üzerinden sorun bizde
        buraya ekleyelim :)
      </h1>
      <Footer />
    </div>
  );
};

export default Faq;
