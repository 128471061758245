import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassHalf,
  faChartColumn,
  faMoon,
  faFaceSmile,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const cardVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

function Card(props) {
  return (
    <motion.div
      className="benefit-box"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.4 }}
      variants={cardVariants}
    >
      <div className="benefit-box__icon">
        <FontAwesomeIcon icon={props.icon} />
      </div>
      <h3 className="benefit-box__title">{props.title}</h3>
      <p className="benefit-box__text">{props.text}</p>
    </motion.div>
  );
}

export default function App() {
  return (
    <section className="section-benefits">
      <div className="container">
        <span className="subheading">Neler Sunuyoruz?</span>
      </div>
      <div className="container grid grid--3-cols">
        <Card
          title={"Deneme Şansı"}
          text={
            "Yatırım yapmaktan çekiniyorsanız denemelik yatırımlar ekleyin ve bir süre takip edin."
          }
          icon={faChartColumn}
        />
        <Card
          title={"Kolay Yönetim"}
          text={
            "Crypto varlıklar, hisse senetleri, gelirler, giderler ve daha fazlasını kolayca ekle/çıkar."
          }
          icon={faFaceSmile}
        />
        <Card
          title={"Zaman Tasarrufu"}
          text={
            "Gerekli bütün her şeye tek uygulamadan ulaşarak tekrar tekrar uygulama değiştirmene gerek yok."
          }
          icon={faHourglassHalf}
        />
        <Card
          title={"Karanlık Mod"}
          text={"Gözlerinizi yormadan dilediğinizce kullanabilirsiniz."}
          icon={faMoon}
        />
        <Card
          title={"4 Dil Desteği"}
          text={
            "Şimdilik Türkçe, İngilizce, İspanyolca ve Almanca desteklenmektedir."
          }
          icon={faComments}
        />
      </div>
    </section>
  );
}
