import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Faq from "./pages/FAQ/Faq";
import Download from "./pages/Download/Download";
import "./App.css";
import Privacy from "./pages/Privacy/Privacy";

function App() {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/contact"} element={<Contact />} />
      <Route path={"/faq"} element={<Faq />} />
      <Route path={"/download"} element={<Download />} />
      <Route path={"/privacy"} element={<Privacy />} />
    </Routes>
  );
}

export default App;
