import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import "./style.css";

export default function Footer() {
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  return (
    <footer className="footer">
      <div>
        <ul className="social-list">
          <li>
            <Link
              className="social-icon"
              to={"https://www.instagram.com/invelist/"}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
          </li>
          <li>
            <Link className="social-icon" to={"https://twitter.com/invelist"}>
              <FontAwesomeIcon icon={faXTwitter} />
            </Link>
          </li>
          <li>
            <Link
              className="social-icon"
              to={"https://www.facebook.com/invelist"}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
          </li>
          <li>
            <Link
              className="social-icon"
              to={"https://www.youtube.com/@invelist"}
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
          </li>
        </ul>
        <ul className="title-list">
          <li className="footer-title">
            <Link to={"/"}>Ana Sayfa</Link>
          </li>
          <li className="footer-title">
            <Link to={"/contact"}>İletişim</Link>
          </li>
          <li className="footer-title">
            <Link to={"/download"}>İndir</Link>
          </li>
          <li className="footer-title">
            <Link to={"/faq"}>SSS</Link>
          </li>
        </ul>
        <p className="footer-text">
          Copyright &copy; {currentYear} by invelist
        </p>
      </div>
    </footer>
  );
}
