import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Privacy = () => {
  return (
    <div>
      <Navbar />
      <section className="privacy">
        <div className="container">
          <span className="subheading">Kullanım Şartları</span>
          <h3 className="heading-tertiary">Son Güncelleme: 19 Mart 2024</h3>
          <h3 className="heading-tertiary">1. Şartların Kabul Edilmesi</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Bu hizmet şartları, uygulamanın kullanıcıları ("siz") ile invelist
            ("biz") arasındaki anlaşmayı oluşturmaktadır. Bu sözleşme
            invelist'in websitesini, uygulamasını ve sosyal medya hesaplarını
            kapsamaktadır. Türkiye'de vergilendirilmekteyiz. Vergi numaramız
            7370376313 (Uludağ). Bu uygulamayı kullanarak bu şartları kabul
            etmiş sayılmaktasınızdır. BU HİZMET KOŞULLARININ TAMAMINI KABUL
            ETMİYORSANIZ UYGULAMAYI KULLANMANIZ YASAKLANIR VE UYGULAMAYI
            KULLANMAYI DURDURMANIZ GEREKLİDİR. Zaman içinde gizlilik ve
            koşullarda değişiklik yapılması durumunda "Son Güncelleme" tarihini
            güncelleyerek (Sayfanın en üstünde yer alıyor) sizi
            bilgilendireceğiz. "Son Güncelleme" tarihini baz alarak güncel
            hizmet şartlarını kontrol ettiğinizden emin olunuz. Uygulamayı
            kullanmaya devam etmeniz durumunda, revize edilmiş Hizmet
            Şartlarındaki değişikliklere tabi olacaksınız ve bu değişikliklerden
            haberdar olmuş ve kabul etmiş sayılacaksınız. Uygulama en az 13
            yaşında olan kullanıcılara yöneliktir. İkamet ettikleri yargı
            bölgesinde reşit olmayan (genellikle 18 yaşın altındaki) tüm
            kullanıcılar, Siteyi kullanmak için ebeveynlerinin veya vasilerinin
            iznine sahip olmalı ve onlar tarafından doğrudan denetlenmelidir.
            Reşit değilseniz, Siteyi kullanmadan önce ebeveyninizin veya
            velinizin bu Hizmet Koşullarını okumasını ve kabul etmesini
            sağlamalısınız.
          </p>
          <h3 className="heading-tertiary">2. Fikri Mülkiyet Hakları</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamayı kullanmaya uygun olmanız koşuluyla, Uygulamaya erişmeniz
            ve Uygulamayı kullanmanız ve yalnızca kişisel, ticari olmayan
            amaçlarla uygun şekilde erişim elde ettiğiniz İçeriğin herhangi bir
            bölümünü indirmeniz veya bir kopyasını yazdırmanız için size sınırlı
            bir lisans verilir. Uygulama, İçerik ve Markalarda size açıkça
            verilmeyen tüm hakları saklı tutuyoruz.
          </p>
          <h3 className="heading-tertiary">3. Kullanıcı Beyanları</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamayı ve Websitesini kullanarak şunları beyan ve garanti
            edersiniz: (1) gönderdiğiniz tüm kayıt bilgilerinin gerçek, doğru,
            güncel ve eksiksiz olacağını; (2) söz konusu bilgilerin doğruluğunu
            koruyacak ve söz konusu kayıt bilgilerini gerektiği şekilde derhal
            güncelleyeceksiniz; (3) yasal kapasiteye sahipsiniz ve bu Hizmet
            Koşullarına uymayı kabul ediyorsunuz; (4) 13 yaşın altında
            değilsiniz; (5) ikamet ettiğiniz yargı bölgesinde reşit değilseniz
            veya reşit değilseniz Siteyi kullanmak için ebeveyn izni aldınız;
            (6) Siteye bir bot, komut dosyası veya başka bir şekilde otomatik
            veya insan dışı yollarla erişmeyeceksiniz; (7) Siteyi herhangi bir
            yasa dışı veya yetkisiz amaç için kullanmayacağınızı ve (8)
            Uygulamayı kullanımınızın yürürlükteki herhangi bir yasa veya
            düzenlemeyi ihlal etmeyeceğini kabul edersiniz. Doğru olmayan,
            hatalı, güncel olmayan veya eksik herhangi bir bilgi vermeniz
            durumunda, hesabınızı askıya alma veya sonlandırma ve Uygulamanın
            (veya herhangi bir bölümünün) mevcut veya gelecekteki tüm ve tüm
            kullanımını reddetme hakkımız vardır.
          </p>
          <h3 className="heading-tertiary">4. Kayıt Olma</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamaya kayıt olarak şifrenizi gizli tutmayı kabul etmiş
            sayılırsınız. Tamamen kendi insiyatifimize bağlı olarak kullanıcı
            adınızın ya da e-postanızın müstehcen, uygunsuz veya sakıncalı
            olduğunu tespit edersek hesabınızı kaldırabilme ya da değişiklik
            yapabilme hakkımızı saklı tutarız.
          </p>
          <h3 className="heading-tertiary">5. Üyelik İptali</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            İstediğiniz zaman üyeliğinizi hesabınızdan iptal edebilirsiniz.
            İptaliniz geçerli üyelik döneminin sonunda geçerli olacaktır.
            Sıkıntı yaşamanız durumunda lütfen bize hello@invelist.com
            adresinden ulaşınız.
          </p>
          <h3 className="heading-tertiary">6. Yasaklar</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamayı veya websiteyi kullanarak şunları yapmayacağınızı kabul
            etmiş sayılırsınız: (1) Bizim yazılı iznimiz olmadığı durumda veri
            tabanına erişmeye çalışmak veya diğer içerikleri almaya çalışmak.
            (2) Kullanıcıların hassas (şifreler, e-postalar vs.) bilgilerine
            yasa dışı yollar ile ya da kullanıcıları kandırarak ulaşmaya
            çalışmak. (3) Bizi (invelist'i) küçük düşürmeye yönelik bir
            çalışmada bulunmak. (4) Uygulamanın veya sitenin güvenlik
            önlemlerini aşmaya çalışmak. (5) Destek hizmetlerimizi uygunsuz bir
            şekilde kullanmaya çalışmak. (6) Uygulamayı veyahut siteyi amacının
            dışında kullanmaya çalışmak. (7) Uygulamaya ya da uygulamaya hizmet
            veren ağlara müdahele etmek, aşırı yük oluşturmak. (8)
            Çalışanlarımızı rahatsız etmek, korkutmak veya tehdit etmek. (9)
            Uygulamanın ve sitenin bir bölümüne erişimi engellemek adına kaynak
            kodlarına erişmeye çalışmak. (10) Uygulamanın yazılımını kopyalamak
            veya uyarlamak. (11) Tersine mühendislik uygulamaya çalışmak. (12)
            Otomatik sistemler, scraper, spider veya benzeri yetkisiz herhangi
            bir yazılımın kullanmak. (13) Kullanıcıların e-posta adreslerini
            toplamaya yönelik bir girişimde bulunmak. (14) Bizim yazılı iznimiz
            olmadan uygulamayı veya siteyi gelir elde etme amacıyla kullanmak.
          </p>
          <h3 className="heading-tertiary">7. Katkı Lisansları</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Kullanıcı (siz) ve uygulama (invelist), Gizlilik Politikasının
            şartlarına ve tercihlerinize (ayarlar dahil) UYGUN olarak
            sağladığınız her türlü bilgiye ve kişisel verilere
            erişebileceğimizi, saklayabileceğimizi, işleyebileceğimizi ve
            kullanabileceğimizi kabul edersiniz. Uygulama ile ilgili öneri veya
            diğer geri bildirimleri göndererek, bu tür geri bildirimleri size
            herhangi bir ücret ödemeden herhangi bir amaçla kullanabileceğimizi
            ve paylaşabileceğimizi kabul etmiş olursunuz. Öneri veya geri
            bildirimlere ilişkin hiçbir beyandan sorumlu değiliz. Bunlardan
            sorumlu olduğunuzu öneri veya geri bildirim gönderirken kabul etmiş
            sayılırsınız.
          </p>
          <h3 className="heading-tertiary">8. Mobil Uygulama Lisansı</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamaya bir mobil cihaz aracılığıyla erişiyorsanız, size mobil
            uygulamayı sahip olduğunuz veya kontrol ettiğiniz kablosuz
            elektronik cihazlara kurma ve kullanma ve Uygulamaya erişme ve
            kullanma konusunda geri alınabilir, münhasır olmayan, devredilemez
            ve sınırlı bir hak veriyoruz. Bu tür cihazlardaki mobil uygulama, bu
            Hizmet Koşullarında yer alan bu mobil uygulama lisansının hüküm ve
            koşullarına tam olarak uygun olacaktır. Şunları YAPAMAZSINIZ: 1.
            yürürlükteki yasaların izin verdiği durumlar dışında, uygulamayı
            kaynak koda dönüştüremez, ters mühendislik uygulayamaz, parçalara
            ayıramaz, kaynak kodunu türetmeye çalışamaz veya uygulamanın
            şifresini çözemezsiniz; 2. uygulamada herhangi bir değişiklik,
            uyarlama, iyileştirme, geliştirme, çeviri veya türev çalışma yapmak;
            3. uygulamaya erişiminiz veya uygulamayı kullanımınızla bağlantılı
            olarak yürürlükteki yasaları, kuralları veya düzenlemeleri ihlal
            etmek; 4. tarafımızdan veya uygulamanın lisans verenleri tarafından
            gönderilen herhangi bir mülkiyet bildirimini (her türlü telif hakkı
            veya ticari marka bildirimi dahil) kaldırmak, değiştirmek veya
            gizlemek; 5. uygulamayı gelir getirici herhangi bir çaba, ticari
            girişim veya tasarlanmadığı veya amaçlanmadığı başka bir amaç için
            kullanmak; 6. uygulamayı aynı anda birden fazla cihaz veya
            kullanıcının erişimine veya kullanımına izin veren bir ağ veya başka
            bir ortam üzerinden kullanılabilir hale getirmek; 7. uygulamayı
            doğrudan veya dolaylı olarak uygulamayla rekabet eden veya herhangi
            bir şekilde onun yerine geçen bir ürün, hizmet veya yazılım
            oluşturmak için kullanmak; 8. uygulamayı herhangi bir web sitesine
            otomatik sorgular göndermek veya herhangi bir istenmeyen ticari
            e-posta göndermek için kullanmak; veya 9. herhangi bir özel bilgiyi
            veya arayüzlerimizden herhangi birini veya diğer fikri
            mülkiyetimizi, uygulamayla birlikte kullanılacak herhangi bir
            uygulamanın, aksesuarın veya cihazın tasarımında, geliştirilmesinde,
            üretiminde, lisanslanmasında veya dağıtımında kullanmak.
          </p>
          <h3 className="heading-tertiary">9. Gönderim Hakları</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Gönderimlerinizdeki herhangi bir mülkiyet (bkz. "Katkı Lisansları")
            hakkının iddia edilen veya fiili ihlali veya kötüye kullanılması
            konusunda bize karşı herhangi bir rücu yapılmayacağını kabul
            edersiniz.
          </p>
          <h3 className="heading-tertiary">10. Uygulama Yönetimi</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Şunları yapma hakkını saklı tutuyoruz, ancak bu yükümlülüğü yerine
            getirmiyoruz: (1) Siteyi bu Hizmet Koşullarının ihlallerine karşı
            izlemek; (2) tamamen kendi takdirimize bağlı olarak yasayı veya bu
            Hizmet Koşullarını ihlal eden herhangi bir kişiye karşı, söz konusu
            kullanıcıyı kolluk kuvvetlerine bildirmek de dahil ancak bunlarla
            sınırlı olmamak üzere uygun yasal işlem başlatacağız; (3) tamamen
            kendi takdirimize bağlı olarak ve sınırlama olmaksızın,
            Katkılarınızın herhangi birini veya herhangi bir bölümünü reddetmek,
            kısıtlamak, kullanılabilirliğini sınırlamak veya (teknolojik olarak
            mümkün olduğu ölçüde) devre dışı bırakmak; (4) tamamen kendi
            takdirimize bağlı olarak ve sınırlama, bildirim veya yükümlülük
            olmaksızın, boyutu aşırı olan veya sistemlerimiz için herhangi bir
            şekilde yük oluşturan tüm dosya ve içerikleri Siteden kaldırmak veya
            başka şekilde devre dışı bırakmak; ve (5) Siteyi haklarımızı ve
            mülkiyetimizi koruyacak ve Sitenin düzgün işleyişini kolaylaştıracak
            şekilde yönetmek.
          </p>
          <h3 className="heading-tertiary">11. Gizlilik Politikası</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Verilerinizin gizliliğine önem veriyoruz. Uygulamayı kullanarak
            gizlilik politikamızı (bu sayfada mevcut) kabul etmiş sayılırsınız.
            Invelist'in Türkiye'de barındırıldığını unutmayınız.
          </p>
          <h3 className="heading-tertiary">12. Kullanım ve Fesih Politikası</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Bu Hizmet Şartları, Siteyi kullandığınız süre boyunca tam olarak
            yürürlükte kalacaktır. BU HİZMET KOŞULLARININ DİĞER HÜKÜMLERİNİ
            SINIRLAMADAN, YALNIZCA KENDİ TAKDİRİMİZE GÖRE VE ÖNCEDEN VEYA
            SORUMLULUK OLMAKSIZIN, HERHANGİ BİR NEDENLE HERHANGİ BİR KİŞİNİN
            SİTEYE ERİŞİMİNİ VE SİTEYİ KULLANMASINI ENGELLEME (BELİRLİ IP
            ADRESLERİNİN ENGELLENMESİ DAHİL) HAKKINI SAKLI TUTUYORUZ VEYA BU
            HİZMET KOŞULLARINDA VEYA HERHANGİ BİR GEÇERLİ YASA VEYA DÜZENLEMEDE
            YER ALAN HERHANGİ BİR BEYANIN, GARANTİ VEYA SÖZLEŞMENİN İHLAL
            EDİLMESİ DAHİL, ANCAK BUNLARLA SINIRLI OLMAMAK ÜZERE, HİÇBİR NEDEN
            OLMAYACAK. SİTEDE KULLANIMINIZI VEYA KATILIMINIZI SONLANDIRABİLİR
            VEYA HESABINIZI VE YAYINLADIĞINIZ HERHANGİ BİR İÇERİĞİ VEYA BİLGİYİ
            HERHANGİ BİR ZAMANDA, UYARI VERMEDEN, YALNIZCA KENDİ TAKDİRİMİZE
            GÖRE SİLEBİLİRİZ. Hesabınızı herhangi bir nedenle sonlandırır veya
            askıya alırsak, üçüncü kişi adına hareket ediyor olsanız bile, kendi
            adınız, sahte veya ödünç alınmış bir ad veya herhangi bir üçüncü
            tarafın adı altında kaydolmanız ve yeni bir hesap oluşturmanız
            yasaktır. Hesabınızı sonlandırma veya askıya almanın yanı sıra,
            hukuki, cezai ve ihtiyati tedbir de dahil ancak bunlarla sınırlı
            olmamak üzere uygun yasal işlemleri başlatma hakkını saklı
            tutuyoruz.
          </p>
          <h3 className="heading-tertiary">
            13. Güncelleme ve Kesinti Politikası
          </h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Sitenin içeriğini herhangi bir zamanda veya herhangi bir nedenle,
            tamamen kendi takdirimize bağlı olarak, bildirimde bulunmaksızın
            değiştirme, tadil etme veya kaldırma hakkını saklı tutuyoruz. Ancak
            Sitemizdeki herhangi bir bilgiyi güncelleme yükümlülüğümüz yoktur.
            Ayrıca herhangi bir zamanda bildirimde bulunmaksızın Sitenin
            tamamını veya bir kısmını değiştirme veya durdurma hakkını da saklı
            tutuyoruz. Sitenin herhangi bir modifikasyonu, fiyat değişikliği,
            askıya alınması veya sonlandırılması nedeniyle size veya herhangi
            bir üçüncü tarafa karşı sorumlu olmayacağız. Sitenin her zaman
            kullanılabilir olacağını garanti edemeyiz. Siteyle ilgili donanım,
            yazılım veya başka sorunlarla karşılaşabilir veya bakım yapmamız
            gerekebilir; bu da kesintilere, gecikmelere veya hatalara neden
            olabilir. Siteyi herhangi bir zamanda veya herhangi bir nedenle size
            bildirimde bulunmaksızın değiştirme, revize etme, güncelleme, askıya
            alma, durdurma veya başka şekilde tadil etme hakkını saklı
            tutuyoruz. Sitenin herhangi bir kesintisi veya devamsızlığı
            sırasında Siteye erişememeniz veya Siteyi kullanamamanızdan
            kaynaklanan herhangi bir kayıp, hasar veya rahatsızlıktan dolayı
            hiçbir sorumluluğumuzun bulunmadığını kabul edersiniz. Bu Hizmet
            Koşullarındaki hiçbir şey, Siteyi sürdürmemizi ve desteklememizi
            veya bunlarla bağlantılı herhangi bir düzeltme, güncelleme veya
            yayın sunmamızı zorunlu kılacak şekilde yorumlanmayacaktır.
          </p>
          <h3 className="heading-tertiary">14. Maddi ve Manevi Zararlar</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            HİÇBİR DURUMDA BİZ VEYA YÖNETİCİLERİMİZ, ÇALIŞANLARIMIZ VEYA
            TEMSİLCİLERİMİZ, KAR KAYBI, GELİR KAYBI, VERİ KAYBI DAHİL OLMAK
            ÜZERE HERHANGİ BİR DOĞRUDAN, DOLAYLI, SONUÇ OLARAK, ÖRNEK OLARAK,
            ARIZİ, ÖZEL VEYA CEZAİ ZARARLAR İÇİN SİZE VEYA HERHANGİ BİR ÜÇÜNCÜ
            TARAFA KARŞI SORUMLU OLMAYACAKTIR.
          </p>
          <h3 className="heading-tertiary">15. Kullanıcı Verileri</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulamanın performansını yönetmek amacıyla Siteye ilettiğiniz
            belirli verileri ve ayrıca Uygulamayı kullanımınızla ilgili verileri
            saklayacağız. Her ne kadar düzenli olarak rutin veri yedeklemeleri
            yapsak da, ilettiğiniz veya Uygulamayı kullanarak
            gerçekleştirdiğiniz herhangi bir faaliyetle ilgili tüm verilerden
            yalnızca siz sorumlusunuz. Bu tür verilerin kaybolması veya
            bozulması konusunda size karşı hiçbir sorumluluğumuzun olmayacağını
            kabul edersiniz ve bu tür verilerin kaybolması veya bozulmasından
            kaynaklanan bize karşı her türlü dava hakkından feragat edersiniz.
          </p>
          <h3 className="heading-tertiary">16. İletişim</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Uygulama veya Websitemiz üzerinden iletişim sekmesinden iletişime
            geçebilirsiniz ya da direkt hello@invelist.com adresinden mail
            gönderebilirsiniz.
          </p>
          <span className="subheading">Gizlilik Politikası</span>
          <h3 className="heading-tertiary">Son Güncelleme: 21 Şubat 2024</h3>
          <h3 className="heading-tertiary">Gizlilik Politikasının Kabulu</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Invelist için olan bu gizlilik bildirimi, aşağıdaki durumlarda
            bilgilerinizi nasıl ve neden toplayabileceğimizi,
            saklayabileceğimizi, kullanabileceğimizi ve/veya paylaşabileceğimizi
            ("işleyebileceğimizi") açıklamaktadır. Hizmetlerimizi aşağıdaki
            durumlarda kullanırsınız: Politikalarımızı ve uygulamalarımızı kabul
            etmiyorsanız lütfen Hizmetlerimizi kullanmayın.
          </p>
          <h3 className="heading-tertiary">1. Hangi Verileri Topluyoruz?</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            (1) Uygulamamıza kayıt olurken gönüllü olarak sağladınız kişisel
            bilgiler (isimler, soyisimler, email adresleri, şifreler). (2)
            Uygulamaya sonradan eklediğiniz bilgiler (yatırımlar, gelirler,
            giderler, hedefler). (3) Ödeme altyapısı iyzico tarafından
            sağlanmaktadır gerekli bilgiye bu link üzerinden ulaşabilirsiniz.
            (4) Uygulama Verileri. Uygulamalarımızı kullanırsanız, bize erişim
            veya izin vermeyi seçmeniz durumunda aşağıdaki bilgileri de
            toplayabiliriz: Mobil Cihaz Verileri. Cihaz bilgilerini (mobil cihaz
            kimliğiniz, modeliniz ve üreticiniz gibi), işletim sistemini, sürüm
            bilgilerini ve sistem yapılandırma bilgilerini, cihaz ve uygulama
            kimlik numaralarını, tarayıcı türünü ve sürümünü, donanım modelini
            İnternet servis sağlayıcısı ve/veya mobil operatörünü otomatik
            olarak topluyoruz ve İnternet Protokolü (IP) adresi (veya proxy
            sunucusu). Uygulamalarımızı kullanıyorsanız, mobil cihazınızla
            ilişkili telefon ağı, mobil cihazınızın işletim sistemi veya
            platformu, kullandığınız mobil cihazın türü, mobil cihazınızın
            benzersiz cihaz kimliği ve bilgiler hakkında da bilgi
            toplayabiliriz. Eriştiğiniz uygulama(lar)ın özellikleri hakkında.
            Push bildirimleri. Hesabınıza veya uygulama(lar)ın belirli
            özelliklerine ilişkin size anlık bildirimler göndermeyi talep
            edebiliriz. Bu tür iletişimleri almaktan vazgeçmek istiyorsanız,
            bunları cihazınızın ayarlarından kapatabilirsiniz. Bu bilgilere
            öncelikle uygulamalarımızın güvenliğini ve çalışmasını sürdürmek,
            sorun giderme ve dahili analiz ve raporlama amaçlarımız için ihtiyaç
            duyulur. Bize sağladığınız tüm kişisel bilgiler doğru, eksiksiz ve
            doğru olmalıdır ve bu tür kişisel bilgilerde herhangi bir değişiklik
            olması durumunda bizi bilgilendirmelisiniz.
          </p>
          <h3 className="heading-tertiary">2. Verileri Nasıl İşliyoruz?</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Kısaca bilgilerinizi hizmetlerimizi sağlamak, geliştirmek ve
            yönetmek, sizinle iletişim kurmak, güvenlik ve dolandırıcılığı
            önlemek ve yasalara uymak amacıyla işleriz. Bilgilerinizi izniniz
            doğrultusunda başka amaçlarla da işleyebiliriz.
          </p>
          <h3 className="heading-tertiary">
            3. Verileri Ne Zaman ve Hangi Durumlarda Paylaşıyoruz?
          </h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Bu bölümde açıklanan belirli durumlarda ve/veya aşağıdaki üçüncü
            taraflarla bilgi paylaşabiliriz. İş Transferleri. Herhangi bir
            birleşme, şirket varlıklarının satışı, finansman veya işimizin
            tamamının veya bir kısmının başka bir şirkete devralınması ile
            bağlantılı olarak veya bunlarla ilgili müzakereler sırasında
            bilgilerinizi paylaşabilir veya aktarabiliriz.
          </p>
          <h3 className="heading-tertiary">
            4. Verileri Ne Kadar Süre Saklayacağız?
          </h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Yasalar (vergi, muhasebe veya diğer yasal gereklilikler gibi) daha
            uzun bir saklama süresi gerektirmedikçe veya buna izin vermedikçe,
            kişisel bilgilerinizi yalnızca bu gizlilik bildiriminde belirtilen
            amaçlar için gerekli olduğu sürece tutacağız. Bu bildirimin hiçbir
            amacı, kişisel bilgilerinizi kullanıcıların bizde hesap sahibi
            olduğu süreden daha uzun süre saklamamızı gerektirmeyecektir.
          </p>
          <h3 className="heading-tertiary">5. Verileri Nasıl Koruyoruz?</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            İşlediğimiz her türlü kişisel bilginin güvenliğini korumak için
            tasarlanmış uygun ve makul teknik ve organizasyonel güvenlik
            önlemlerini uyguladık. Ancak, bilgilerinizin güvenliğini sağlamaya
            yönelik önlemlerimize ve çabalarımıza rağmen, İnternet üzerinden
            hiçbir elektronik aktarımın veya bilgi depolama teknolojisinin %100
            güvenli olduğu garanti edilemez; dolayısıyla bilgisayar
            korsanlarının, siber suçluların veya diğer yetkisiz üçüncü
            tarafların bu güvenlik önlemlerine tabi tutulmayacağına dair söz
            veya garanti veremeyiz. güvenliğimizi bozabilir ve bilgilerinizi
            uygunsuz bir şekilde toplayabilir, bunlara erişebilir, çalabilir
            veya değiştirebilir. Kişisel bilgilerinizi korumak için elimizden
            gelenin en iyisini yapacak olsak da, kişisel bilgilerin
            Hizmetlerimize ve Hizmetlerimizden iletilmesinin riski size aittir.
            Hizmetlere yalnızca güvenli bir ortamda erişmelisiniz.
          </p>
          <h3 className="heading-tertiary">6. Gizlilik Haklarınız Nelerdir?</h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Hesabınızı silmek istemeniz durumunda. Hesabınızı sonlandırma
            talebiniz üzerine hesabınızı ve bilgilerinizi aktif
            veritabanlarımızdan devre dışı bırakacağız veya sileceğiz. Ancak
            dolandırıcılığı önlemek, sorunları gidermek, herhangi bir
            soruşturmaya yardımcı olmak, yasal şartlarımızı uygulamak ve/veya
            geçerli yasal gerekliliklere uymak için bazı bilgileri
            dosyalarımızda tutabiliriz.
          </p>
          <h3 className="heading-tertiary">
            7. Gizlilik Politikası Güncellenir mi?
          </h3>
          <p
            style={{
              color: "#fff",
              marginBottom: "1.2rem",
              fontSize: "1.6rem",
            }}
          >
            Kısaca evet. Bu gizlilik bildirimini zaman zaman güncelleyebiliriz.
            Güncellenen sürüm, güncellenmiş bir "Son Güncelleme" tarihi ile
            belirtilecek ve güncellenen sürüm, erişilebilir olur olmaz yürürlüğe
            girecektir. Bu gizlilik bildiriminde önemli değişiklikler yapmamız
            durumunda, söz konusu değişikliklere ilişkin belirgin bir bildirim
            yayınlayarak veya doğrudan size bir bildirim göndererek sizi
            bilgilendirebiliriz.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Privacy;
