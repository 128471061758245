import React from "react";
import "./style.css";

export default function Form() {
  return (
    <section className="section-form">
      <form
        name="contact"
        className="container"
        method="POST"
        action="/contact"
        netlify
      >
        <span className="subheading">İLETİŞİM</span>
        <input type="hidden" name="form-name" value="contact" />
        <input
          name="name"
          type="text"
          className="form-input"
          placeholder="İsim"
          required
        />
        <input
          name="email"
          type="email"
          className="form-input"
          placeholder="E-posta"
          required
        />
        <textarea
          name="text"
          className="form-input"
          placeholder="Mesajınız"
          required
        ></textarea>
        <input type="submit" value="Gönder" className="form-button" />
      </form>
    </section>
  );
}
