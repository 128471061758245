import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import HeroSecond from "../../images/Hero-2.png";
import Hero from "../../images/Hero.png";
import "./style.css";

export default function Header() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);
  return (
    <>
      <div ref={ref} className="parallax-section">
        <motion.h1 style={{ y: textY }} className="parallax-text z-10">
          <FontAwesomeIcon icon={faArrowDown} id="arrow" />
        </motion.h1>

        <motion.div
          className="parallax-background z-0"
          style={{
            backgroundImage: `url(${Hero})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            y: backgroundY,
          }}
        />
        <div
          className="parallax-background z-20"
          style={{
            backgroundImage: `url(${HeroSecond})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        />
      </div>
    </>
  );
}
